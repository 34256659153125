import React from 'react'

const BeanGIF = ()=>{
    return(
        <div className='gif'>
            <img srcSet="https://media1.giphy.com/media/5ndklThG9vUUdTmgMn/200w.webp?cid=790b76113bc98c4184e70ad8cbe5c8f1ef78a57af6961b31&amp;rid=200w.webp&amp;ct=s ,https://media1.giphy.com/media/5ndklThG9vUUdTmgMn/giphy.webp?cid=790b76113bc98c4184e70ad8cbe5c8f1ef78a57af6961b31&amp;rid=giphy.webp&amp;ct=s ,"></img> 
            <a target = '_blank'  rel="noreferrer" href="https://giphy.com/stickers/coffee-working-stressed-5ndklThG9vUUdTmgMn">Gif from Giphy</a>   
        </div>
    )
}

export default BeanGIF