import React from 'react'

export const Call = (props)=>{
    return(
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 0C13.4325 0 0 13.4325 0 30C0 46.5675 13.4325 60 30 60C46.5675 60 60 46.5675 60 30C60 13.4325 46.5675 0 30 0ZM38.6125 44.5675C29.4025 48.7775 15.11 20.9925 24.1125 16.2975L26.745 15L31.11 23.5225L28.505 24.805C25.7675 26.2725 31.4675 37.405 34.2675 36.0475L36.8475 34.785L41.2475 43.2775L38.6125 44.5675Z" fill={props.color}/>
        </svg>
    )
}

export const Github = (props)=>{
    return(
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 0C13.435 0 0 13.4325 0 30C0 43.255 8.595 54.5 20.5175 58.4675C22.015 58.745 22.5 57.815 22.5 57.025V51.44C14.155 53.255 12.4175 47.9 12.4175 47.9C11.0525 44.4325 9.085 43.51 9.085 43.51C6.3625 41.6475 9.2925 41.6875 9.2925 41.6875C12.305 41.8975 13.89 44.78 13.89 44.78C16.565 49.365 20.9075 48.04 22.62 47.2725C22.8875 45.335 23.665 44.01 24.525 43.2625C17.8625 42.5 10.8575 39.9275 10.8575 28.435C10.8575 25.1575 12.03 22.4825 13.9475 20.3825C13.6375 19.625 12.61 16.5725 14.24 12.4425C14.24 12.4425 16.76 11.6375 22.4925 15.5175C24.885 14.8525 27.45 14.52 30 14.5075C32.55 14.52 35.1175 14.8525 37.515 15.5175C43.2425 11.6375 45.7575 12.4425 45.7575 12.4425C47.39 16.575 46.3625 19.6275 46.0525 20.3825C47.9775 22.4825 49.14 25.16 49.14 28.435C49.14 39.9575 42.1225 42.495 35.4425 43.2375C36.5175 44.1675 37.5 45.9925 37.5 48.7925V57.025C37.5 57.8225 37.98 58.76 39.5025 58.465C51.415 54.4925 60 43.25 60 30C60 13.4325 46.5675 0 30 0Z" fill={props.color}/>
        </svg>

    )
}

export const LinkedIn = (props)=>{
    return(
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 0C13.4325 0 0 13.4325 0 30C0 46.5675 13.4325 60 30 60C46.5675 60 60 46.5675 60 30C60 13.4325 46.5675 0 30 0ZM25 40H20V25H25V40ZM22.5 22.7725C20.9825 22.7725 19.75 21.5325 19.75 20C19.75 18.47 20.98 17.2275 22.5 17.2275C24.02 17.2275 25.25 18.47 25.25 20C25.25 21.5325 24.0175 22.7725 22.5 22.7725ZM42.5 40H37.505V32.8475C37.505 28.145 32.5 28.5425 32.5 32.8475V40H27.5V25H32.5V27.7325C34.68 23.6925 42.5 23.3925 42.5 31.6025V40Z" fill={props.color}/>
        </svg>
    )
}

export const Mail = (props)=>{
    return(
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 31.7825L0.0375 7.5H59.965L30 31.7825ZM16.4375 27.2275L0 13.905V45.1575L16.4375 27.2275ZM43.5625 27.2275L60 45.1575V13.905L43.5625 27.2275ZM39.67 30.38L30 38.2175L20.33 30.38L0.055 52.5H59.945L39.67 30.38Z" fill={props.color}/>
        </svg>
    )
}

export const Whatsapp = (props)=>{
    return(
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.0775 15.43C22.125 15.43 15.66 21.895 15.6575 29.845C15.655 33.09 16.6075 35.52 18.205 38.0625L16.75 43.3825L22.205 41.95C24.65 43.4 26.9825 44.27 30.0675 44.2725C38.0125 44.2725 44.485 37.805 44.4875 29.8575C44.49 21.89 38.05 15.4325 30.0775 15.43ZM38.5575 36.04C38.1975 37.0525 36.465 37.975 35.6325 38.1C34.885 38.2125 33.94 38.2575 32.9025 37.9275C32.2725 37.7275 31.465 37.46 30.4325 37.015C26.085 35.1375 23.2475 30.76 23.03 30.4725C22.8125 30.1825 21.26 28.1225 21.26 25.99C21.26 23.8575 22.38 22.8075 22.7775 22.375C23.175 21.9425 23.6425 21.8325 23.9325 21.8325L24.7625 21.8475C25.0275 21.86 25.385 21.7475 25.7375 22.5925C26.0975 23.46 26.965 25.5925 27.0725 25.81C27.18 26.0275 27.2525 26.28 27.1075 26.57C26.9625 26.86 26.89 27.04 26.675 27.2925L26.025 28.0525C25.8075 28.2675 25.5825 28.5025 25.835 28.9375C26.0875 29.3725 26.9575 30.79 28.245 31.94C29.9 33.4175 31.2975 33.875 31.73 34.09C32.1625 34.305 32.415 34.27 32.67 33.9825C32.9225 33.6925 33.7525 32.7175 34.0425 32.2825C34.3325 31.85 34.62 31.92 35.0175 32.065C35.415 32.21 37.545 33.2575 37.9775 33.475C38.41 33.6925 38.7 33.8 38.8075 33.98C38.92 34.16 38.92 35.0275 38.5575 36.04ZM30 0C13.4325 0 0 13.4325 0 30C0 46.5675 13.4325 60 30 60C46.5675 60 60 46.5675 60 30C60 13.4325 46.5675 0 30 0ZM30.0725 47.2C27.17 47.2 24.31 46.47 21.7775 45.09L12.585 47.5L15.045 38.5125C13.5275 35.8825 12.7275 32.8975 12.73 29.8425C12.7325 20.28 20.5125 12.5 30.0725 12.5C34.7125 12.5025 39.0675 14.3075 42.34 17.585C45.615 20.8625 47.4175 25.22 47.415 29.855C47.4125 39.4175 39.6325 47.2 30.0725 47.2Z" fill={props.color}/>
        </svg>
    )
}