import React from 'react'

const Logo = ()=>{
    return(
        <h1 className='logo'>
            &lt;create/&gt;
        </h1>        
    )
}

export default Logo